import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../Profile/Profile.scss";
import Layout from "../../layouts/Layout";
import ProfileMenuBar from "../ProfileMenuBar";
import { useDispatch, useSelector } from "react-redux";
import { getSportIcon } from "../../utils/reuse";
import { getSports } from "../../services/api";
import { useToast } from "../../context/ToastContext";

const Sports = () => {
    const dispatch = useDispatch();
    const { showToast } = useToast();
    const sessionData = useSelector(state => state?.auth?.sessionData);
    const [sports, setSports] = useState([]);
    const [loading, setLoading] = useState(false);

    const headers = useMemo(() => {
        return {
            sessionid: sessionData.sessionId,
        };
    }, [sessionData.sessionId]);

    const getSportList = useCallback(() => {
        setLoading(true);
        getSports(headers, dispatch, (response) => {
            if (response.result === "SUCCESS") {
                const sports = response?.data;
                setSports(sports?.length > 0 ? sports?.sort((a, b) => a.name.localeCompare(b.name)) : []);
                if (sports?.length === 0) {
                    showToast("warn", "No records found");
                }
            } else {
                setSports([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
            setLoading(false);
        });
    }, [dispatch, headers, showToast]);

    useEffect(() => {
        getSportList();
    }, [getSportList, sessionData.sessionId]);

    return (
        <>
            <Layout backBtn={'home'} backBtnLabel="Home">
                <section className="profile-page-section">
                    <div className="profile-wrapper">
                        <div className="profile-tabs-section">
                            <div className="m-0">
                                <h1>Assigned Sports</h1>
                            </div>
                            <div className="grid">
                                <div className="col-9">
                                    <div className="form-section">
                                        <div className="section">
                                            <div className="form-header">
                                                <p>Sports</p>
                                            </div>
                                            <div className="form-body">
                                                <div className="sports-block">
                                                    {
                                                        loading ? (
                                                            <div>
                                                                <i className="pi pi-spin pi-spinner"></i>
                                                            </div>
                                                        )
                                                            : (sports?.length > 0 ?
                                                                sports?.map(sport => {
                                                                    return (
                                                                        <div className='sports-list' key={sport.id}>
                                                                            <div className='flex gap-2 align-items-center'>
                                                                                <div className='sports-icon'>{getSportIcon(sport.id + 'b')}</div>
                                                                                <span>{sport.name}</span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                :
                                                                <div style={{ fontSize: '14px' }}>No sports found</div>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="vertical-menu">
                                        <ProfileMenuBar />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Sports;