import * as PR from "../../../../prime-react/index";
import * as Yup from 'yup';
import { fileUploadLimit } from "../../../../utils/reuse";

export const initialValues = {
    name: "",
    description: "",
    role: "member",
    status: "",
    sports: [],
    location: null,
    Managedby: null,
    profilePhoto: null,
    tournamentLevels: [],
};

export const createPayloadObject = (params, sportOptions, formType) => {
    // Common base values for all roles
    const baseValues = {
        role: params.role,
        description: params.description,
        name: params.name,
        status: params.status?.toLowerCase(),
    };
    
    if(params.id) {
        baseValues.id = params.id;
    }

    let sportsPayload;
    if(sportOptions.length === params.sports.length) {
        sportsPayload = [999]
    } else {
        sportsPayload = params.sports.map(sport => sport.id)
    }
    // Common values for roles with sports and tournamentLevels
    let sportsValues = {};

    if (formType === 'new' || params.status === 'Active') {
        sportsValues = params.sports ? {
            sports: sportsPayload,
            tournamentLevels: params.sports.some(sport => sport.name.toLowerCase() === 'tennis') 
                ? params.tournamentLevels.map(level => level.id) 
                : [],
        } : {};
    }

    // Define role-specific values based on the role
    const roleCreators = {
        analyst: baseValues,
        member: { ...baseValues, ...sportsValues },
        managedmember: { ...baseValues, ...sportsValues, managingGroupID: params.Managedby?.id },
        regulator: { ...baseValues, locationID: params.location?.id },
        governingbody: { ...baseValues, ...sportsValues },
        
    };

    // Create form data
    const formData = new FormData();
    formData.append("data", JSON.stringify(roleCreators[params.role]));
   
    // Append profile photo if present
    if (params.profilePhoto) {
        formData.append("attachments", params.profilePhoto);
    }

    return formData;
};


export const validationSchema = Yup.object({
    name: Yup.string().required("Group name is required"),
    description: Yup.string().required("Description is required"),
    profilePhoto: Yup.mixed()
        .nullable()
        .test("fileSize", `File size must be less than ${fileUploadLimit.label}`, (value) => {
            return !value || value.size <= fileUploadLimit.value;
        })
        .test("fileType", "Only image files are allowed", (value) => {
            if (!value) return true;
            const acceptedTypes = ["image/jpeg", "image/png", "image/gif"];
            return acceptedTypes.includes(value.type);
        }),
    role: Yup.string().required("Role is required"),
    status: Yup.string().required("Status is required"),
    sports: Yup.array()
        .of(
            Yup.object().shape({
                id: Yup.number().required("Sport ID is required"),
            })
        )
        .when("role", {
            is: (role) => role === "member" || role === "governingbody" || role==='managedmember',
            then: (schema) => schema.required("Sports is required").min(1, "At least one sport is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
    Managedby: Yup.object().when("role", {
        is: (role) => role === "managedmember",
        then: (schema) => schema.required("Managed by is required"),
        otherwise: (schema) => schema.notRequired(),
    }),
    location: Yup.object().when("role", {
        is: (role) => role === "regulator",
        then: (schema) => schema.required("Location is required"),
        otherwise: (schema) => schema.notRequired(),
    }),
    tournamentLevels: Yup.array()
        .of(
            Yup.object().shape({
                id: Yup.number().required("Tournament ID is required"),
            })
        )
        .when(["role", "sports"], ([role, sports], schema) => {
            if ((role === "member" || role === "governingbody" || role==='managedmember') && sports.some((sport) => sport?.name && sport?.name.toLowerCase() === "tennis")) {
                // If role is "member" or "governingbody" and sports contain "tennis"
                return schema.min(1, "Tournament level is required");
            }
            // If conditions are not met, it's not required
            return schema.notRequired();
        }),
});

export const formContent = {
    new: {
        title: "Create Group",
        description: "Categorize the alert according to the relevant group",
        submit: "Create Group",
        initialValues,
        successMsg:"Group created successfully"
    },
    edit: {
        title: "Edit group",
        description: "Update the group details",
        submit: "Update Group",
        initialValues,
        successMsg:"Group updated successfully"
    }
}

export const FooterContent = ({submitting,submitDisable, onClose, id }) => (
    <div>
        <PR.Button
            label="Cancel"
            onClick={onClose}
            outlined
            className="logout-btns"
        />
        <PR.Button
            label={formContent[id]?.submit}
            type="submit"
            outlined
            loading={submitting}
            disabled={!submitDisable}
            autoFocus
            className="logout-btns click-btn"
            form="userForm"
        />
    </div>
);