import { Navigate, Route, Routes } from "react-router-dom"
import Home from "../modules/Members/Home/Home"
import Alerts from "../modules/Members/Alerts/Alerts"
import CreateAlert from "../shared/CreateAlert/CreateAlert"
import AlertDetails from "../shared/AlertDetails/AlertDetails"
import MyAnalytics from "../modules/Members/MyAnalytics/MyAnalytics"
import MyAnalyticsDetails from "../modules/Members/MyAnalytics/MyAnalyticsDetails"
import CaseUpdates from "../shared/CaseUpdates/CaseUpdates"
import NewsLayout from "../shared/News/NewsLayout"
import NewsCategory from "../shared/NewsCategory/NewsCategory"
import SportingSanction from "../modules/Members/SportingSanction/SportingSanction"
import NewsDetails from "../shared/News/NewsDetails"
import SportingSanctionDetail from "../modules/Members/SportingSanctionDeatils/SportingSanctionDetails"
import WeeklyIntegrityPreview from "../modules/Members/WeeklyIntegrityPreview/WeeklyIntegrityPreview"
import ExternalLinks from "../shared/ExternalLinks/ExternalLinks"
import Profile from "../shared/Profile/Profile"
import Sports from "../shared/Sports/Sports"
import Help from "../pages/Help/Help"
import { PrivateRoute } from "./commonRoute"
import PageNotFound from "../pages/PageNotFound/PageNotFound"


const MemberRoutes = ({ handleTabChange, setActiveIndex, activeIndex }) => {
    return <Routes>
            <Route element={<PrivateRoute />}>
                <Route path='/' element={<Navigate to='/login' />} />
                <Route path='/login' element={ <Navigate to='/home' />} />
                <Route path='/otp' element={ <Navigate to='/home' />} />
                <Route path='/home' element={<Home handleTabChange={handleTabChange} />} />
                <Route path='/alerts' element={<Alerts setActiveIndex={setActiveIndex} activeIndex={activeIndex} />} />
                <Route path='/create-alert' element={<CreateAlert />} />
                <Route path='/alert-details/:id' element={<AlertDetails />} />
                <Route path='/my-analytics' element={<MyAnalytics />} />
                <Route path='/my-analytics-details' element={<MyAnalyticsDetails />} />
                <Route path='/case-updates' element={<CaseUpdates />} />
                <Route path='/news' element={<NewsLayout />} />
                <Route path="/news-category" element={<NewsCategory />} />
                <Route path='/sporting-sanction' element={<SportingSanction />} />
                <Route path="/news-details" element={<NewsDetails />} />
                <Route path="/sanction-details" element={<SportingSanctionDetail />} />
                <Route path="/weekly-integrity" element={<WeeklyIntegrityPreview />} />
                <Route path='/external-links' element={<ExternalLinks />} />
                <Route path='/profile-details' element={<Profile />} />
                <Route path='/sports' element={<Sports />} />
                <Route path='/help' element={<Help />} />
                <Route path='/page-not-found' element={<PageNotFound />} />
                <Route path='*' element={<Navigate to='/page-not-found' />} />
            </Route>
        </Routes>;

}

export default MemberRoutes;