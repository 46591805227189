
import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute } from './commonRoute';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import RegulatorHome from '../modules/Regulator/Home/Home';
import RegulatorAlerts from '../modules/Regulator/Alerts/Alerts';
import NewsLayout from '../shared/News/NewsLayout';
import ExternalLinks from '../shared/ExternalLinks/ExternalLinks';
import NewsCategory from '../shared/NewsCategory/NewsCategory';
import NewsDetails from '../shared/News/NewsDetails';
import Help from '../pages/Help/Help';
import Profile from '../shared/Profile/Profile';
import Reports from '../shared/Reports/Reports';
import ReportDetail from '../shared/Reports/ReportDetail';
import NewsAndUpdates from '../modules/Analyst/NewsAndUpdates/NewsAndUpdates';
import PreviewReport from '../modules/Analyst/Reports/PreviewReport/PreviewReport';
import DownloadReport from '../modules/Analyst/Reports/DownloadReport/DownloadReport';
import Sports from '../shared/Sports/Sports';

const RegulatorRoutes = () => {

    return <Routes>
        <Route element={<PrivateRoute />} >
          <Route path='/' element={<Navigate to='/login' />} />
          <Route path='/login' element={ <Navigate to='/home' />} />
          <Route path='/otp' element={ <Navigate to='/home' />} />
          <Route path='/home' element={ <RegulatorHome /> } />
          <Route path='/alerts' element={ <RegulatorAlerts />} />
          <Route path='/news' element={ <NewsLayout /> } />
          <Route path='/external-links' element={ <ExternalLinks /> } />
          <Route path="/news-category" element={ <NewsCategory /> } />
          <Route path="/news-details" element={ <NewsDetails /> } />
          <Route path='/help' element={ <Help /> } />
          <Route path='/profile-details' element={ <Profile /> } />
          <Route path='/sports' element={<Sports />} />
          <Route path='/reports' element={ <Reports /> } />
          <Route path='/report-detail' element={ <ReportDetail /> } />
          <Route path='/news' element={ <NewsAndUpdates /> } />
          <Route path='/preview-report' element={ <PreviewReport /> } />
          <Route path='/download-report' element={ <DownloadReport /> } />
          <Route path='/page-not-found' element={<PageNotFound />} />
          <Route path='*' element={<Navigate to='/page-not-found' />} />
        </Route>
    </Routes>


}
export default RegulatorRoutes