import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as PR from "../../../prime-react/index";
import Layout from "../../../layouts/Layout";
import "./UserManagement.scss";
import {
    getuserManagementGroupsnewAPI,
    getSportsnewAPI,
    getLocationsnewAPI,
    getGroupsnewAPI,
    getTournamentLevelsnewAPI,
} from "../../../services/api";
import { useSelector } from "react-redux";
import { pagecount } from "../../../utils/reuse";
import { action, groupName, Header, PaginationTemplate, status, userCount } from "./helper";
import { FormPopUp } from "./FormPopUp/FormPopUp";
import { useToast } from "../../../context/ToastContext";

const UserManagement = () => {
    const { showToast } = useToast();
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [optionLoading, setOptionLoading] = useState(false);
    const query = useRef({ limit: pagecount + 1, offset: 0, search: '', role: '' });
    const sessionData = useSelector((state) => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const [formPopUp, setFormPopUp] = useState({ visible: false, id: '', userId:'', role:'' });
    const formOptions = useRef(null)
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        setLoading(true);
        const { result, data, error } = await getuserManagementGroupsnewAPI(new URLSearchParams(query.current).toString(), headers);
        if (result === 'SUCCESS') setUserData(data);
        else {
            setUserData([]);
            showToast(error.severity, error.errorMsg || error.summary);
        };
        setLoading(false);
        return result === 'SUCCESS';
    }, [headers, showToast]);

    useEffect(() => {
        fetchData();
        (async()=>{
            setOptionLoading(true)
            const { data: unsortedSports } = await getSportsnewAPI(headers);
            const { data: unsortedLocations } = await getLocationsnewAPI(headers);
            const { data: unsortedMemberIds } = await getGroupsnewAPI(2, headers);
            const { data: unsortedTournamentLevels } = await getTournamentLevelsnewAPI(headers);
            setOptionLoading(false)

            //
            const sports = unsortedSports?.sort((a, b) => a.name.localeCompare(b.name))
            const locations = unsortedLocations?.sort((a, b) => a.name.localeCompare(b.name))
            const memberIds = unsortedMemberIds?.sort((a, b) => a.name.localeCompare(b.name))
            const tournamentLevels = unsortedTournamentLevels?.sort((a, b) => a.name.localeCompare(b.name))

            formOptions.current = {
                sports,
                locations,memberIds,tournamentLevels}; 
        })();
    }, [fetchData, headers])

    const closeFormPopUp = () => { document.body.classList.remove('p-overflow-hidden'); setFormPopUp({ visible: false, id: '', userId:'', role:'' }) }

    const onSubmitted = response =>{closeFormPopUp(); showToast(response.severity, response.detail);} 

    const viewDetailsHandler = ({ id, name, status, role }) => navigate(`/all-users?${new URLSearchParams({ id, groupName: name, status, role }).toString()}`);

    const editHandler = v =>  {setFormPopUp({ visible: true,...v,  userId:v.id, id: 'edit' });}
 
    return <Layout backBtn={"home"} backBtnLabel="Home">
        <section className="usermanagement-section all-alerts-section all-tags-section mt-5">
            <div className="m-0">
                <h1>User Management</h1>
            </div>
            <div className="alerts-tab mt-2">
                <div className="grid grid-nogutter">
                    <div className="card col-12 relative">
                        <PR.DataTable
                            value={userData?.slice(0, pagecount)}
                            stripedRows className="data-table"
                            footer={<PaginationTemplate query={query.current} data={userData} refetch={fetchData} />}
                            header={<Header query={query.current} setFormPopUp={() => { setFormPopUp({ visible: true, id: 'new', userId:'', role:'' }) }} refetch={fetchData} />}
                            loading={loading}
                        >
                            <PR.Column field="name" header="User Groups" body={groupName}  />
                            <PR.Column field="description" header="Description"  />
                            <PR.Column body={userCount} header="No.of Profiles" />
                            <PR.Column body={status} header="Status" />
                            <PR.Column body={action.bind(null, viewDetailsHandler, editHandler)} header="Action" className="last-column" />
                        </PR.DataTable>
                    </div>
                </div>
            </div>
        </section>
        {!!formPopUp.visible && <FormPopUp options={formOptions.current} refetch={fetchData} optionLoading={optionLoading} data={formPopUp} headers={headers} onSubmitted={onSubmitted} onClose={closeFormPopUp} />}
    </Layout>
}

export default UserManagement;
