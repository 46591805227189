import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import * as PR from "../prime-react/index";
import { toastDisplayTime } from '../utils/reuse';
import { useSelector } from 'react-redux';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const isMember = useSelector((state) => state.auth.role) === "member";
  const toast = useRef(null);
  const toastKeys = useMemo(() => ({
    "success": "Success",
    "error": "Error",
    "info": "Info",
    "warn": "Warning"
  }), []);
  
  const showToast  = useCallback((severity, messageId, key ='errorMsg') => {
    const id = toastKeys[severity];
    if (toast.current && !isMember) {
        toast.current.show({
            life: toastDisplayTime,
            severity: severity,
            summary: id,
            detail: messageId ?? 'Something went wrong',
        });
    }
  }, [isMember, toastKeys]);

  return (
    <ToastContext.Provider value={{showToast}}>
      <PR.Toast ref={toast} position="top-right" baseZIndex={9999} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};