import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import * as PR from "../../prime-react/index";
import { useDispatch, useSelector } from 'react-redux';
import { getFileDetails, toggleFeaturedNews } from '../../services/api';
import { DateFormat, getNewsTypeLabel } from '../../utils/reuse';
import { useToast } from '../../context/ToastContext';

const NewsItem = ({ news, Avatar, newsType, handleDeleteNews, fetchNews, deletedNewsIds }) => {
    const { showToast } = useToast();
    const  { pathname } = useLocation();
    const isMember = useSelector(state => state.auth.role) === 'member';
    const isRegulator = useSelector(state => state.auth.role) === 'regulator';
    const isManagedMember = useSelector(state => state.auth.role) === 'managedmember';
    const [attachmentImage, setAttachmentImage] = useState(null);
    const menuRight = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const toggleFeatured = () => {
        const obj = {
            featured: !news.featured
        }
        toggleFeaturedNews(news?.id, obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                showToast("success", news.featured? 'News removed from featured successfully':'News marked as featured successfully');
                setTimeout(() => {
                    fetchNews()
                  }, 2000);
             
            } else {
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        });
    };

    const getDeleteLabel = () => {
        switch (newsType) {
            case "generalnews":
                return "Delete news";
            case "integritypreviews":
                return "Delete preview";
            case "sportingsanctions":
                return "Delete sanction"
            default:
                return "Delete news"
        }
    }
    const items = [
        ...(newsType !== 'sportingsanctions' ? [{
            label: news?.featured ? 'Remove feature' : 'Mark as featured',
            icon: news?.featured ? 'pi pi-star-fill' : 'pi pi-star',
            command: toggleFeatured,
        }] : []),
        {
            label: 'Edit Details',
            icon: 'pi pi-pencil',
            command: () => gotoEditNews(news.id, news.newsType),
        },
        {
            label: getDeleteLabel(),
            icon: 'pi pi-trash',
            command: () => handleDeleteNews(news.id, news.newsType),
        }
    ];

    const gotoEditNews = (id, newsType) => {
        navigate(`/edit-details?id=${id}&newsType=${newsType}`);
    };
    useEffect(() => {
        if (news?.fileAttachments && !deletedNewsIds?.includes(news.id) && newsType === news.newsType) {
            const attachments = JSON.parse(news?.fileAttachments);
            attachments?.forEach(attachment => {
                if (attachment?.attachmentType === "coverImage") {
                    getFileDetails(attachment?.key, headers, dispatch, response => {
                        if (response.result === 'SUCCESS') {
                            setAttachmentImage(URL.createObjectURL(response?.data));
                        } else {
                            const error = response?.error;
                            showToast(error.severity, error?.errorMsg || error?.summary);
                        }
                    });
                }
            });
        }
    }, [deletedNewsIds, dispatch, headers, news?.fileAttachments, news.id, news.newsType, newsType, showToast]);

    const gotoNewsDetails = (id) => {
        if (isMember || isRegulator || isManagedMember) {
            if(pathname === '/sporting-sanction'){
                navigate(`/sanction-details?id=${id}`);
            }else{
                navigate(`/news-details?id=${id}`);
            }
        } else {
            navigate(`/news-detail?id=${id}&newsType=${newsType}`);
        }
    };

    const renderLeftFooter = (news, newsType) => {
        if(pathname === '/news' && (isMember || isRegulator || isManagedMember)){
            return(
                <>
                    <li>
                        <i className="pi pi-user"></i>{" "}
                        <span className="author">
                            {news?.createdByUser}
                        </span>
                    </li>
                    <li>
                        <i className="pi pi-calendar"></i>{" "}
                        <span>{DateFormat(news?.createdAt)}</span>
                    </li>
                </>
            )
        }else if(newsType === "sportingsanctions"){
            return (
                <ul className='chips-ul gap-3'>
                    {news?.keywords && news?.keywords?.split(',')?.map(keyword => (
                        <li key={keyword}>{keyword}</li>
                    ))}
                </ul>
            )
        }else{
            return (
                <>
                    <li>
                        <i className="pi pi-user"></i>{" "}
                        <span className="author">
                            {news?.createdByUser}
                        </span>
                    </li>
                    <li>
                        <i className="pi pi-calendar"></i>{" "}
                        <span>{DateFormat(news?.createdAt)}</span>
                    </li>
                </>
            )
        }
    }

    const renderRightFooter = (news,newsType) => {
        if(pathname === '/news' && (isMember || isRegulator || isManagedMember)){
            return (
                <li>
                    <i className="pi pi-book"></i>{" "}
                    <span>{news?.categoryName ?? getNewsTypeLabel(news?.newsType)}</span>
                </li>
            )
        }else if (newsType === "sportingsanctions") {
            return (
                <li>
                    <i className="pi pi-calendar"></i>{" "}
                    <span>{DateFormat(news?.createdAt)}</span>
                </li>
            )
        }else {
            return (
                <li>
                    <i className="pi pi-book"></i>{" "}
                    <span>{news?.categoryName ?? getNewsTypeLabel(news?.newsType)}</span>
                </li>
            )
        }

    }

    return (
        <div className="news-list">
            <div className="flex gap-4">
                <div className="news-cover p-0">
                    {attachmentImage ? (
                        <PR.Image
                            src={attachmentImage}
                            alt={news?.title}
                            className="news-img"
                        />
                    ) : (
                        <PR.Image
                            src={Avatar}
                            alt={news?.title}
                            className="news-img"
                        />
                    )}
                </div>
                <div className="news-content w-full py-2 px-0">
                    <div className="flex justify-content-between w-full h-full">
                        <div className="flex justify-content-between flex-column w-full">
                            <div className='flex flex-column'>
                                <h3 className='title'>{news?.title}</h3>
                                <div
                                    onClick={() => gotoNewsDetails(news?.id)}
                                    className="desc-content"
                                    dangerouslySetInnerHTML={{
                                        __html: news?.description,
                                    }}
                                ></div>
                            </div>
                            <div className="flex align-items-center justify-content-between">
                                <ul className="flex list-none align-items-center gap-4">
                                    {renderLeftFooter(news, newsType)}
                                </ul>
                                <ul className="flex list-none align-items-center gap-4">
                                    {renderRightFooter(news, newsType)}
                                </ul>
                            </div>
                        </div>
                        {(!isMember && !isRegulator && !isManagedMember) && (
                            <div className="dropdown-popup">
                                <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className="export-popup" popupAlignment="right" />
                                <PR.Button icon="pi pi-ellipsis-v" className="info-button" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsItem;
