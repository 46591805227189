import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import BackMenuLayout from "../layouts/BackMenuLayout";

export const PublicRoute = () => {
    const isAuthenticated = useSelector(state => state.auth.isLoggedIn);
    return isAuthenticated ? <Navigate to='/home' /> : <Outlet />;
}

export const PrivateRoute = () => {
    const isAuthenticated  = useSelector(state => state.auth.isLoggedIn)
    return isAuthenticated ? <BackMenuLayout ><Outlet /></BackMenuLayout>: <Navigate to='/login' />;
}
