import React, { useState } from "react";
import * as PR from "../../prime-react/index";
import "../Login/Login.scss";
import { logo } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import secureLocalStorage from "react-secure-storage";
import * as Yup from 'yup';
import { forgotpasswordApi } from "../../services/api";
import { useToast } from "../../context/ToastContext";
import { Link } from 'react-router-dom';
import Footer from "../../layouts/Footer";

const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;

const ForgotPassword = () => {
    const { showToast } = useToast();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    
    const onSubmit = async v => {
        setLoading(true);
        const {result,error} = await forgotpasswordApi(v);
        if(result==='SUCCESS'){
            setTimeout(()=>{ navigate('/login') },1000)
            showToast("success", "Link has been successfully sent on your registered email address");
        }
        else showToast(error.severity, error.errorMsg);
        setLoading(false);
    }

    const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({ 
        initialValues: {email: secureLocalStorage.getItem('userData')?.email ?? ''},
        validationSchema: Yup.object().shape({email: Yup.string().matches(emailReg,'Invalid Email').required('Email is required')}),
        onSubmit
    });

    return (
    <>
        <section className="login-section">
            <div className="grid grid-nogutter">
                <div className="col-12">
                    <div className="login-wrapper flex align-items-center justify-content-center">
                        <div className="login-info">
                            <PR.Image src={logo} alt="IBIA Logo" className="logo-img" />
                            <h1>Forgot Password</h1>
                            <p>We'll send a verification link on your registered email address.</p>
                            {/* Form section starts here */}
                            <form autoComplete="off" onSubmit={handleSubmit} >
                                <div className='grid'>
                                    <div className="col-12 md:col-12 mb-0">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="email">Registered email</label>
                                            <PR.InputText name="email" id="email" onChange={handleChange} onBlur={handleBlur} value={values.email} aria-describedby="email" className="w-12" placeholder="Enter email address" autoComplete="off" />
                                            {(touched.email && errors.email) && <div className="error-message">{errors.email}</div>}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <PR.Button loading={loading} label="Send Reset Link" type='submit' className="signin-button w-12" />
                                    </div>
                                    <div className="col-12">
                                        <p className="text-center link-p"><Link to="/login">Sign In?</Link></p>
                                    </div>
                                </div>
                            </form>
                            {/* Form section ends here */}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </section>
    </>
  );
};

export default ForgotPassword;