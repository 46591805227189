import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as PR from "../../../../prime-react/index";
import "./AllUsers.scss";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Layout from "../../../../layouts/Layout";
import { useDispatch, useSelector } from 'react-redux';
import { getGroupsbyId, updateUserProfiles, createProfile, updateGroupPermissions, getSportsnewAPI, getLocationsnewAPI, getGroupsnewAPI, getTournamentLevelsnewAPI } from '../../../../services/api';
import { fileUploadLimit, getSportIcon } from "../../../../utils/reuse";
import ImageDisplay from '../../../../shared/ImageDisplay';
import { FormPopUp } from '../FormPopUp/FormPopUp';
import { permissionID } from '../../../../shared/common';
import { every, isEqual } from 'lodash';
import { useToast } from '../../../../context/ToastContext';

const AllUsers = () => {
    const { showToast } = useToast();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get('id');

    const [formPopUp, setFormPopUp] = useState({ visible: false, id: '', userId:'', role:'' });
    const [optionLoading, setOptionLoading] = useState(false);
    const formOptions = useRef(null)

    const [visible, setVisible] = useState(false);
    const [statusDropdown, setStatusDropdown] = useState(null);
    const navigate = useNavigate();
    const menuRight = useRef(null);
    let imageUploadRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [groupData, setGroupData] = useState({});
    const [manageGroupData, setManagedGroupData] = useState({});
    const [allSportList, setAllSportList] = useState([]);
    const [assignedSports, setAssignedSports] = useState([]);
    const [loading, setLoading] = useState(false);
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const dispatch = useDispatch();
    const [initialGroupData, setInitialGroupData] = useState({});
    const [permissionsState, setPermissionsState] = useState({
        overview: false,
        analytics: false,
        news: false,
        reports: false,
        caseupdates: false,
        export: false,
    });

    useEffect(() => {
        if (groupData && groupData?.permission) {
            setPermissionsState({
                overview: groupData.permission.includes('overview'),
                analytics: groupData.permission.includes('analytics'),
                news: groupData.permission.includes('news'),
                reports: groupData.permission.includes('reports'),
                caseupdates: groupData.permission.includes('caseupdates'),
                export: groupData.permission.includes('export'),
            });
        }
    }, [groupData]);

    const handleSwitchChange = (permissionName) => {
        setPermissionsState(prevState => ({ ...prevState,  [permissionName]: !prevState[permissionName]}));
    };

    const permissionhandler = () => {
        const permissionMapping = { ...permissionID };
        for(let key in permissionsState) if(!permissionsState[key]) delete permissionMapping[key];
        const postData = Object.values(permissionMapping);
        updateGroupPermissions(id, postData , headers, dispatch, res => {
            if(res.result === 'SUCCESS'){
                setGroupData(prev => ({ ...prev,  permission: Object.keys(permissionMapping) }));
            }
        });
    }
    const getGroupsHandler = useCallback(() => {
        setLoading(true);
        getGroupsbyId(id, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const profiles = responseData;
                const result = profiles?.length > 0 ? profiles : [];
                setGroupData(result[0]);
                setManagedGroupData(result[0] && result[0]);
                setInitialGroupData(result[0] && result[0]);

            } else {
                const error = response?.error;
                showToast(error.severity, error.errorMsg || error.summary);
            }
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [id, headers, dispatch]);

    useEffect(() => {
        if (id) {
            getGroupsHandler();
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        (async()=>{
            setOptionLoading(true)
            const { data: unsortedSports } = await getSportsnewAPI(headers);
            const sports = unsortedSports?.sort((a, b) => a.name.localeCompare(b.name))
            setAllSportList(sports);
            const { data: unsortedLocations } = await getLocationsnewAPI(headers);
            const { data: unsortedMemberIds } = await getGroupsnewAPI(2, headers);
            const { data: unsortedTournamentLevels } = await getTournamentLevelsnewAPI(headers);
            setOptionLoading(false)

            
            const locations = unsortedLocations?.sort((a, b) => a.name.localeCompare(b.name))
            const memberIds = unsortedMemberIds?.sort((a, b) => a.name.localeCompare(b.name))
            const tournamentLevels = unsortedTournamentLevels?.sort((a, b) => a.name.localeCompare(b.name))
            formOptions.current = {sports,locations,memberIds,tournamentLevels}; 
        })();
    }, [headers]);

    const closeFormPopUp = () => { document.body.classList.remove('p-overflow-hidden'); setFormPopUp({ visible: false, id: '', userId:'', role:'' }) }
    
    const onSubmitted = response =>{closeFormPopUp(); showToast(response.severity, response.detail);}

    const goToManagedProfiles = (details) => {
        setActiveIndex(0)
        const queryParams = new URLSearchParams({
            id: details.id,
            groupName: details.groupName,
            status: details.status,
            role:details.role,
        }).toString();
        navigate(`/all-users?${queryParams}`);
    };
    
    const allowedPermissions = useMemo(() => {
        if (groupData?.role === 'member' || groupData?.role === 'Managedmember') {
            return ['overview', 'analytics', 'news', 'caseupdates', 'export'];
        } else if (groupData?.role === 'regulator' || groupData?.role === 'governingbody') {
            return ['overview', 'news', 'reports'];
        }
        return [];
    }, [groupData]);
    const isPermissionAllowed = (permission) => allowedPermissions.includes(permission);

    const statusValue = [
        { value: 'Active' },
        { value: 'Inactive' }
    ];

    const profile = (rowData) => (
        <div className="flex align-items-center gap-3">
            <ImageDisplay profileImage={rowData?.userImage || ''} type="user" />
            <div className="event-column">
                <p>{rowData.name}</p>
            </div>
        </div>
    );

    const email = (rowData) => (
        <div className="flex align-items-center gap-3">
            <div className="event-column" style={{width:'100%'}}>
                <PR.InputText 
                style={{width:'100%'}}
                    value={rowData.email} 
                    readOnly
                    placeholder="Enter email"
                    className="small-text-input" 
                />
            </div>
        </div>
    );

    const status = (rowData) => {
        const currentStatus = rowData.userStatus ? rowData.userStatus.charAt(0).toUpperCase() + rowData.userStatus.slice(1).toLowerCase() : '';

        return (
            <div className="card">
                <PR.Dropdown 
                    value={currentStatus} 
                    onChange={(e) => {
                        const updatedUsers = groupData.users?.map(user => {
                            if (user.id === rowData.id) {
                                return {
                                    ...user,
                                    userStatus: e.value 
                                };
                            }
                            return user;
                        });
                        setGroupData(prev => ({ ...prev, users: updatedUsers }));
                    }} 
                    options={statusValue} 
                    optionLabel="value"
                    placeholder="Select status" 
                    className="w-full alert-drop-down small-text-input" 
                />
            </div>
        );
    };

    const permissions = (rowData) => {
        const isNewsPermissionChecked = 
            (Array.isArray(rowData.userPermission) && rowData.userPermission.includes('news')) ||
            rowData.userPermission === 'news'; 

        return (
            <div className="card">
                <PR.Checkbox
                    id={`news-${rowData.id}`}
                    onChange={e => {
                        const updatedUsers = groupData.users?.map(user => {
                            if (user.id === rowData.id) {
                                let updatedPermissions;
                                
                                if (e.checked) {
                                    updatedPermissions = Array.isArray(user.userPermission) 
                                        ? [...new Set([...user.userPermission, 'news'])] 
                                        : ['news'];
                                } else {
                                    updatedPermissions = Array.isArray(user.userPermission) 
                                        ? user.userPermission?.filter(perm => perm !== 'news')
                                        : null;
                                }
                                
                                return {
                                    ...user,
                                    userPermission: updatedPermissions && updatedPermissions?.length > 0 ? updatedPermissions : null
                                };
                            }
                            return user;
                        });
                        setGroupData(prev => ({ ...prev, users: updatedUsers }));
                    }}
                    checked={isNewsPermissionChecked}
                />
                <label htmlFor={`news-${rowData.id}`} className="ml-2 font-medium small-text">News updates</label>
            </div>
        );
    };

    const footerContent = (
        <div>
            <PR.Button label="Cancel" onClick={() => setVisible(false)} outlined className="logout-btns" />
            <PR.Button 
                label="Create User" 
                outlined autoFocus 
                className="logout-btns click-btn"
                type="submit"
                form="profileForm"
            />
        </div>
    );

    const items = [
        {
            label: 'Edit Details',
            icon: 'pi pi-pencil',
            command: () => { !loading && setFormPopUp({ visible: true, ...groupData,  userId:groupData.id, id: 'edit' });},
        },
    ];
    
    const managedGroupAction = (rowData) => (
        <div className="flex gap-2 align-items-center">
            <PR.Button label="View Details" type='submit' severity="secondary" outlined className="view-details-button" onClick={() =>
                goToManagedProfiles({
                    id: rowData.id,
                    groupName: rowData.name,
                    status: rowData.status,
                    role:rowData.role
                })
            } />

        </div>
    );

    const managedGroupStatus = (rowData) => (
        <div className="flex gap-2 align-items-center">
            <div className={`status ${rowData.status.toLowerCase()}`}>
                {rowData?.status ? rowData.status?.charAt(0)?.toUpperCase() + rowData.status?.slice(1)?.toLowerCase() : ''}
            </div>
        </div>
    );

    const managedGroupProfile = (rowData) => (
        <div className="flex gap-2 align-items-center">
            <div>
                {rowData?.usersCount ?? 0} {rowData?.usersCount === 1 ? 'profile' : 'profiles'}
            </div>
        </div>
    );
 
    const handleDeleteCoverImage = (setFieldValue) => {
        setFieldValue("profilePhoto", null);
    };

    const initialValues = {
        name:"",
        email:"",
        status:statusDropdown,
        profilePhoto:null
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("User name is required")
            .min(3, "User name must be at least 3 characters")
            .max(20, "User name must be at most 20 characters"),
        email: Yup.string().required("Email address is required"),
        status: Yup.string().required("Status is required"),
        profilePhoto: Yup.mixed()
            .nullable()
            .test("fileSize", `File size must be less than ${fileUploadLimit.label}`, (value) => {
                return !value || value.size <= fileUploadLimit.value;
            })
            .test("fileType", "Only image files are allowed", (value) => {
                if (!value) return true;
                const acceptedTypes = ["image/jpeg", "image/png", "image/gif"];
                return acceptedTypes.includes(value.type);
            }),

    })

    const handleSubmit = (formValues, { setSubmitting }) => {
        const payload = {
            email: formValues.email,
            name: formValues.name,
            status: statusDropdown.toLowerCase(),
        };
        
        const formData = new FormData();
        formData.append("data", JSON.stringify(payload));
        if (formValues.profilePhoto) {
            formData.append("attachments", formValues.profilePhoto);
        }

        createProfile(id,formData, headers, dispatch, (response) => {
            if (response.result === "SUCCESS") {
                showToast("success", "Profile created successfully");
                getGroupsHandler()
                setVisible(false);
                setStatusDropdown("");
              } else {
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
              }
        })
        setSubmitting(false);
    }

    const managedName = (rowData) => (
        <div className="flex align-items-center gap-3">
            <ImageDisplay profileImage={rowData?.imageSrc??rowData?.image} type="user" />
            <div className="event-column">
                <p>{rowData.name}</p>
            </div>
        </div>
    );

    const groupUser = groupData.users?.map(e=> ({...e,
        userStatus: e.userStatus?.toLowerCase(),
        userPermission: !e.userPermission ? 'all' : Array.isArray(e.userPermission)? e.userPermission[0] : e.userPermission
    }));
    
    const saveChangesHandler = () => {
        const updatedUsersPayload = groupData.users?.reduce((acc, user) => {
            const initialUser = initialGroupData.users?.find(u => u.id === user.id);
            if (initialUser) {
                const hasChanged = user.email !== initialUser.email || 
                                   user.userStatus !== initialUser.userStatus || 
                                   !arraysEqual(user.userPermission, initialUser.userPermission);
                
                if (hasChanged) {
                    const userPermission = Array.isArray(user.userPermission) && user.userPermission.includes('news') ? 'news' : 'all';
    
                    acc.push({
                        name: user.name,
                        id: user.id,
                        email: user.email,
                        userPermission,
                        status: user.userStatus?.toLowerCase()
                    });
                }
            }
            return acc;
        }, []);
    
        if (updatedUsersPayload?.length > 0) {
            updateUserProfiles(id, updatedUsersPayload, headers, dispatch, (response) => {
                if (response.result === 'SUCCESS') {
                    setInitialGroupData(p=>({...p, users: groupUser}))
                    showToast("success", "User profile updated successfully");
                } else {
                    showToast("error", "Failed to update user profile");
                }
            });
        } else {
            showToast("info", "No changes to update");
        }
    };
    
    const saveChangesDisable = isEqual(initialGroupData.users, groupUser)

    const arraysEqual = (a, b) => {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;
    
        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    };

    const getAssignedSportsList = useCallback(() => {
        const assignedSportIDs = groupData?.sports ?? [];
        const filteredSports = assignedSportIDs.includes(999)
            ? allSportList // If assignedSportIDs contains 999, return the entire allSportList
            : allSportList?.filter(sport => assignedSportIDs?.includes(sport.id)); 
        setAssignedSports(filteredSports);
    }, [allSportList, groupData?.sports]);

    useEffect(() => {
        if (activeIndex === 4) {
            getAssignedSportsList();
        }
    }, [activeIndex, getAssignedSportsList]);

    const permissionSaveChanges = isEqual(Object.keys(permissionsState).reduce((a, i) => ({ ...a, [i]: !!groupData?.permission?.includes(i) }), {}), permissionsState)
    const permissionUpdateNotAllowed = every(Object.values(permissionsState), v => v === false)
    
    return (
        <>
            <Layout backBtn={"user-management"} backBtnLabel={"User Management"}>
                <section className="all-alerts-section all-users-section">
                    <div className="m-0 flex align-items-center justify-content-between">
                        <div className='flex align-items-center gap-3'>
                            {
                                loading 
                                ? <PR.Skeleton height='32px' width='380px' />
                                : <>
                                    <h1>{groupData?.name}{groupData?.usersCount && <span>&nbsp;&nbsp;:&nbsp;&nbsp;{groupData?.usersCount ? `${groupData?.usersCount} ${groupData?.usersCount > 1 ? "Users" : "User"} ` : null}</span>}</h1>
                                    <span className="status managed"> {`${groupData?.role?.charAt(0)?.toUpperCase()}${groupData?.role?.slice(1)}`}</span>
                                    <span className="status success">{`${groupData?.status?.charAt(0)?.toUpperCase()}${groupData?.status?.slice(1)}`}</span>
                                </>
                            }
                        </div>
                        <div>
                            <PR.Button label="Create User" icon="pi pi-plus" className="create-button" onClick={() => setVisible(true)} />
                            <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className='export-popup' popupAlignment="right" />
                            <PR.Button icon="pi pi-ellipsis-v" className="info-button" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
                        </div>
                    </div>
                    <div className="alerts-tab">
                        {activeIndex === 0 && (
                            <div className='flex w-full justify-content-end save-btn-section'>
                                <PR.Button label="Update Users" disabled={saveChangesDisable} className='user-management-save-button' onClick={saveChangesHandler}/>
                            </div>
                        )}
                        <PR.TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <PR.TabPanel header="Users">
                                <div className="grid grid-nogutter">
                                    <div className="card col-12 relative">
                                        <PR.DataTable
                                            value={groupData?.users || []}
                                            stripedRows
                                            className="data-table"
                                            loading={loading}
                                        >
                                            <PR.Column body={profile} header="User Info"></PR.Column>
                                            <PR.Column body={email} field="email" header="Email"></PR.Column>
                                            <PR.Column body={status} header="Status" sortable></PR.Column>
                                            <PR.Column body={permissions} header="Permissions"></PR.Column>
                                        </PR.DataTable>
                                    </div>
                                </div>
                            </PR.TabPanel>
                            <PR.TabPanel header="Permissions">
                                <div className='flex w-full justify-content-end save-btn-section'>
                                    <PR.Button label="Update Permissions" disabled={permissionSaveChanges||permissionUpdateNotAllowed} className='user-management-save-button' onClick={permissionhandler}/>
                                </div>
                                <div className="permissions-section">
                                    <div key={groupData.id} className="group-section">
                                        <div className='flex align-items-center justify-content-between'>
                                            <h3>Allow Access</h3>
                                        </div>
                                        <div className="switch-button flex gap-2 align-items-center">
                                            <PR.InputSwitch checked={permissionsState.overview} onChange={() => handleSwitchChange('overview')}    disabled={!isPermissionAllowed('overview')}  />
                                            <span className='font-medium'>Alerts overview</span>
                                        </div>
                                        <div className="switch-button flex gap-2 align-items-center">
                                            <PR.InputSwitch checked={permissionsState.analytics} onChange={() => handleSwitchChange('analytics')}    disabled={!isPermissionAllowed('analytics')}  />
                                            <span className='font-medium'>Member analytics</span>
                                        </div>
                                        <div className="switch-button flex gap-2 align-items-center">
                                            <PR.InputSwitch checked={permissionsState.news} onChange={() => handleSwitchChange('news')} disabled={!isPermissionAllowed('news')} />
                                            <span className='font-medium'>News and updates</span>
                                        </div>
                                        <div className="switch-button flex gap-2 align-items-center">
                                            <PR.InputSwitch checked={permissionsState.reports} onChange={() => handleSwitchChange('reports')}  disabled={!isPermissionAllowed('reports')} />
                                            <span className='font-medium'>Reports page</span>
                                        </div>
                                        <div className="switch-button flex gap-2 align-items-center">
                                            <PR.InputSwitch checked={permissionsState.caseupdates} onChange={() => handleSwitchChange('caseupdates')} disabled={!isPermissionAllowed('caseupdates')} />
                                            <span className='font-medium'>Case updates search</span>
                                        </div>
                                        <div className="switch-button flex gap-2 align-items-center">
                                            <PR.InputSwitch checked={permissionsState.export} onChange={() => handleSwitchChange('export')} disabled={!isPermissionAllowed('export')}  />
                                            <span className='font-medium'>Enable export</span>
                                        </div>
                                    </div>
                                    {permissionUpdateNotAllowed&&<div className='info-message align-items-center gap-1'> <i className="pi pi-info-circle"></i> A minimum of one permission is necessary.</div>}
                                </div>
                            </PR.TabPanel>
                            {groupData.role === "member" && (
                                <PR.TabPanel header=" Managed groups">
                                    <div className="grid grid-nogutter">
                                        <div className="card col-12 relative">
                                            <PR.DataTable
                                                value={manageGroupData?.managedGroups || []}
                                                stripedRows
                                                className="data-table"
                                                emptyMessage="No records found."
                                            >
                                                <PR.Column field='name' header="Group name" body={managedName}></PR.Column>
                                                <PR.Column field="description" header="Description"></PR.Column>
                                                <PR.Column field="profiles" header="Profiles" sortable body={managedGroupProfile}></PR.Column>
                                                <PR.Column body={managedGroupStatus} header="Status" sortable></PR.Column>
                                                <PR.Column body={managedGroupAction} header="Action" className="last-column"></PR.Column>
                                            </PR.DataTable>
                                        </div>
                                    </div>
                                </PR.TabPanel>
                            )}
                            {groupData.role === "managedmember" && (
                                <PR.TabPanel header="Managed by">
                                    <div className="grid grid-nogutter">
                                        <div className="card col-12 relative">
                                            <PR.DataTable
                                               value={groupData?.managingGroup ? [groupData.managingGroup] : []}  
                                               stripedRows
                                               className="data-table"
                                               emptyMessage="No records found."
                                            >
                                                <PR.Column field='name' header="Group name" body={managedName}></PR.Column>
                                                <PR.Column field="description" header="Description"></PR.Column>
                                                <PR.Column field="profiles" header="Profiles" sortable body={managedGroupProfile}></PR.Column>
                                                <PR.Column body={managedGroupStatus} header="Status" sortable></PR.Column>
                                                <PR.Column body={managedGroupAction} header="Action" className="last-column"></PR.Column>
                                            </PR.DataTable>
                                        </div>
                                    </div>
                                </PR.TabPanel>
                            )}
                            <PR.TabPanel header="Sports">
                                <div className="permissions-section sports-section">
                                    <div className='card'>
                                        <h3>Assigned Sports</h3>
                                    </div>
                                    <div className='mt-4 sports-block'>
                                        {
                                            
                                            loading ? <PR.Skeleton height='32px' width='380px' />
                                            : assignedSports?.length > 0 ? assignedSports?.map(sport => 
                                                <div className='sports-list' key={sport.id}>
                                                    <div className='flex gap-2 align-items-center'>
                                                        <div className='sports-icon'>{getSportIcon(sport.id + 'b')}</div>
                                                        <span>{sport.name}</span>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div style={{fontSize: '14px'}}>No sports found</div>
                                        }
                                    </div>
                                </div>
                            </PR.TabPanel>
                        </PR.TabView>
                    </div>
                </section>
                {/* Create profile Dialog */}
                <PR.Dialog 
                    visible={visible} 
                    footer={footerContent} 
                    className="logout-dialog response-dialog tags-dialog"
                >

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) =>
                            handleSubmit(values, { setSubmitting })
                        }
                    >
                        {({
                            isSubmitting,
                            errors,
                            touched,
                            values,
                            handleChange,
                            setFieldValue,
                        }) => (
                            <Form id="profileForm">
                                <div className="dialog-header">
                                    <div className='mb-5'>
                                        <h2>Create profile</h2>
                                        <p>Categorize the alert according to the relevant tag</p>
                                    </div>
                                </div>
                                <div className="dialog-body-section">
                                    <div className="flex flex-column gap-2 mb-3">
                                        <label htmlFor="firstname">Profile photo</label>
                                        <div className="card file-upload flex align-items-center">
                                            <PR.FileUpload 
                                                auto
                                                mode="basic" 
                                                name="profilePhoto"
                                                accept="image/*"
                                                ref={imageUploadRef}
                                                chooseLabel="Choose File"
                                                customUpload
                                                onSelect={(event) => {
                                                    if (imageUploadRef.current) {
                                                      imageUploadRef.current.clear();
                                                    }
                                                    setFieldValue("profilePhoto", event.files[0]);   
                                                }} 
                                            />
                                            <span className="ml-2">(Add file less than {fileUploadLimit.label})</span>
                                        </div>  
                                        {values.profilePhoto && (
                                            <div className="uploaded-file-container flex flex-column gap-2">
                                            <div className="uploaded-files">
                                                {values.profilePhoto.name}
                                                <i
                                                className="pi pi-trash"
                                                onClick={() => handleDeleteCoverImage(setFieldValue)}
                                                />
                                            </div>
                                            </div>
                                        )}
                                        {errors.profilePhoto && (
                                            <div className="error-message">
                                            {errors.profilePhoto}
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex flex-column form-input gap-2 mb-3">
                                        <label htmlFor="name">Full name</label>
                                        <PR.InputText 
                                            id="name"
                                            name= "name" 
                                            type="text" 
                                            placeholder='Enter profile name' 
                                            aria-describedby="name"
                                            value={values.name}
                                            onChange={(e) => {
                                                handleChange(e)
                                            }}
                                        />
                                        {errors.name && touched.name && (
                                            <div className="error-message">{errors.name}</div>
                                        )}
                                    </div>
                                    <div className="flex flex-column form-input gap-2 mb-3">
                                        <label htmlFor="email">Email address</label>
                                        <PR.InputText 
                                            id="email"
                                            name="email"
                                            type="text" 
                                            placeholder='Enter profile email' 
                                            aria-describedby="email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && touched.email && (
                                            <div className="error-message">{errors.email}</div>
                                        )}
                                    </div>
                                    <div className="flex flex-column form-input gap-2">
                                        <label htmlFor="name">Status</label>
                                        <PR.Dropdown
                                            id="status"
                                            name="status"
                                            value={statusDropdown}
                                            onChange={(e) => {
                                                setStatusDropdown(e.value)
                                                setFieldValue("status", e.value)
                                            }}
                                            options={statusValue} optionLabel="value"
                                            placeholder="Select status"className="multi-select-dropdown" 
                                        />
                                        {errors.status && touched.status && (
                                            <div className="error-message">{errors.status}</div>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </PR.Dialog>
            </Layout>
            {!!formPopUp.visible && <FormPopUp options={formOptions.current} refetch={getGroupsHandler} optionLoading={optionLoading} data={formPopUp} headers={headers} onSubmitted={onSubmitted} onClose={closeFormPopUp} />}
        </>
    );
};

export default AllUsers;
