import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as PR from "../../../prime-react/index";
import "./Analytics.scss";
import Layout from '../../../layouts/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getAlertsCount, getGroupsNames } from '../../../services/api';
import moment from 'moment';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { authActions } from '../../../store/auth';
import { jsPDF } from "jspdf";
import { optionsforBarGraphs, optionsforBarGraphsWithPercentage } from '../../../utils/reuse';

const AnalystAnalytics = () => {
    const navigate = useNavigate();

    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);
    const dispatch = useDispatch();
    const isExport = useSelector(state => state.auth?.exportPermission);

    const menuRight = useRef(null);
    const [visible, setVisible] = useState(false);
    const [exportType, setExportType] = useState(null);

    const [selectedMemberAlerts, setSelectedMemberAlerts] = useState(null);
    const [selectedMemberResRates, setSelectedMemberResRates] = useState(null);
    const [raisedAlerts, setRaisedAlerts] = useState(); 
    const [responseRates, setResponseRates] = useState(); 
    const [alertsChart, setAlertsChart] = useState({});
    const [responseChart, setReponseChart] = useState({});

    const [chartOptions, setChartOptions] = useState(optionsforBarGraphs);
    const [chartOptionsWithPerc, setChartOptionsWithPerc] = useState(optionsforBarGraphsWithPercentage);
    const [membersData, setMembersData] = useState([]);

    const [dates, setDates] = useState([
        new Date(new Date().getFullYear(), new Date().getMonth(), 1), 
        new Date()
    ]);

    const [type, setType] = useState();

    const chartRefAlerts = useRef(null);
    const chartRefResponse = useRef(null);

    const handleDownloadFile = (exportType) => {
        const currDate = `_${moment(dates[0]).format('DD_MMM_YYYY')}${moment(dates[1]).isValid() ? `_to_${moment(dates[1]).format('DD_MMM_YYYY')}` : ''}`;
        const downloadedFilename =  (type === 'tarm' ? 'total-alerts-raised-by-members' : 'response-rates-by-members') + currDate;
        const csvConfig = mkConfig({ useKeysAsHeaders: true , filename: downloadedFilename});

        if(exportType === 'png'){
            let chartRef = type === 'tarm' ? chartRefAlerts : chartRefResponse;
            if (chartRef.current) {
                const base64Image = chartRef.current.getBase64Image();
                const link = document.createElement('a');
                link.href = base64Image;
                link.download = `${downloadedFilename}`;
                link.click();
            }
        }
        else if(exportType === 'csv'){
            const csv = generateCsv(csvConfig)(type === 'tarm' ? raisedAlerts : responseRates);
            download(csvConfig)(csv)
        }

        else if (exportType === 'pdf') {
            let chartRef = type === 'tarm' ? chartRefAlerts : chartRefResponse;
            if (chartRef.current) {
                const base64Image = chartRef.current.getBase64Image();
                const doc = new jsPDF();
                doc.addImage(base64Image, 'PNG', 10, 10);
                doc.save(`${downloadedFilename}.pdf`);
            }
        }
        setExportType(null);
        setVisible(false);
    }

    const items = [
        {
            label: 'View',
            icon: 'pi pi-eye',
            disabled: type === 'tarm' ? !raisedAlerts : !responseRates,
            command: () => navigate(`/my-analytics-details?type=${type}`)

        },
        {
            label: 'Export',
            icon: 'pi pi-upload',
            disabled: !isExport || (type === 'tarm' ? !raisedAlerts : !responseRates),
            command: () => setVisible(true)
        }
    ];
    
        const emptyData = {
            labels: null,
            datasets: [
                {
                    label: 'Members',
                    data: null,
                    backgroundColor: '#3067C2',
                    borderRadius: 5,
                    barThickness: 25,
                },
            ]
        };
    
        const processData = (data, setDataFunction) => {
            if (data) {
                const filteredData = data?.filter(item => {
                    const value = item?.alertCount || item?.responseRate || 0;
                    return value > 0;
                });
                const labels = filteredData?.map(item => `${item?.groupName}`);
                const dataValues = filteredData?.map(item => item?.alertCount || item?.responseRate || 0);
    
                const chartData = {
                    labels: labels,
                    datasets: [
                        {
                            data: dataValues,
                            backgroundColor: '#3067C2',
                            borderRadius: 5,
                            barThickness: 25,
                        },
                    ]
                };
    
                setDataFunction(chartData);
                setChartOptions(optionsforBarGraphs);
                setChartOptionsWithPerc(optionsforBarGraphsWithPercentage);    
            } else {
                setDataFunction(emptyData);
            }
        };
    
    
    

    // API for getting data for alertsRaised and ResponseRate
    const getAlertRaisedHandler = useCallback((selectedGroups) => {
        const obj = {
            type: "tarm",
        }; 
        if (dates && dates?.length > 0) {
            const startDate= moment(dates[0]).format('YYYY-MM-DD');
            const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        if(selectedGroups?.length > 0)
            {
                obj.members  = selectedGroups?.map(group  => group.id).join(',');
            }
        getAlertsCount(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                setRaisedAlerts(responseData?.alertsRaisedPerMember);
                processData(responseData?.alertsRaisedPerMember, setAlertsChart);
                const analyticsData = {
                    type: 'tarm',
                    selectedDate: dates,
                    selectedData: selectedGroups,
                    data: responseData?.alertsRaisedPerMember
                 };
                 dispatch(authActions.updateAnalystAlertRaisedAnalytics(analyticsData));
            }
        })
    // eslint-disable-next-line
    }, [dispatch, headers, dates]);

    
    // API for getting data for alertsRaised and ResponseRate
    const getResponseRateHandler = useCallback((selectedGroups) => {
        const obj = {
            type: "rrpm",
        }; 
        if (dates && dates?.length > 0) {
            const startDate= moment(dates[0]).format('YYYY-MM-DD');
            const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        if(selectedGroups?.length > 0)
            {
                obj.members  = selectedGroups?.map(group  => group.id).join(',');
            }
        getAlertsCount(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                setResponseRates(responseData?.responseRatePerMember);
                processData(responseData?.responseRatePerMember, setReponseChart);
                const analyticsData = {
                    type: 'rrpm',
                    selectedDate: dates,
                    selectedData: selectedGroups,
                    data: responseData?.responseRatePerMember
                 };
                 dispatch(authActions.updateAnalystResponseRateAnalytics(analyticsData));
            }
        })
    // eslint-disable-next-line
    }, [dispatch, headers, dates]);

    //API to get the groupsNames
    const getGroupsHandler = useCallback(() => {
        getGroupsNames(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response?.data ? response?.data?.sort((a, b) => a.name.localeCompare(b.name)) : [] ;
                setMembersData(responseData);
            }
        })
    }, [dispatch, headers]);

    useEffect(() => {
        getAlertRaisedHandler(selectedMemberAlerts);
    },[getAlertRaisedHandler, selectedMemberAlerts, dates]);

    useEffect(() => {
        getResponseRateHandler(selectedMemberResRates);
    },[getResponseRateHandler, selectedMemberResRates, dates]);

    useEffect(() => {
        getGroupsHandler();
    },[getGroupsHandler]);

  const footerContent = (
    <div>
        <PR.Button label="Cancel" onClick={() => setVisible(false)} outlined className="logout-btns" />
        <PR.Button label="Export" onClick={() => handleDownloadFile(exportType)} disabled={!exportType} outlined autoFocus className={`logout-btns click-btn`} />
    </div>
    );

    return (
        <>
            <Layout backBtn={"home"} backBtnLabel="Home">
                <section className="my-analytics-tab">
                    <div className="m-0 flex align-items-center justify-content-between">
                        <div className='heading-card'>
                            <h1>Member analytics</h1>
                        </div>
                        <div className="card">
                            <PR.Calendar 
                                value={dates} 
                                onChange={(e) => setDates(e.value)} 
                                selectionMode="range" 
                                iconPos="left" 
                                readOnlyInput 
                                showIcon 
                                className='date-calendar' 
                                placeholder='Date'
                                dateFormat={"dd M yy"}
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                    <div className="analytics-wrapper">
                        <div className="grid align-items-start mt-2">

                            {/* Raised Alert Chart */}
                            <div className="col-6">
                                <div className="info-box">
                                        <div className='pr-0'>
                                            <h4>Total Alerts Raised</h4>
                                            <p>The number of alerts raised by each organisation in the selected date range</p>
                                        </div>
                                        <div className="card flex">
                                                <div className="flex flex-row gap-3 w-full">
                                                      <PR.MultiSelect 
                                                        filter 
                                                        value={selectedMemberAlerts} 
                                                        onChange={(e) => setSelectedMemberAlerts(e.value)} 
                                                        options={membersData} 
                                                        optionLabel="name" 
                                                        placeholder="Select Members" 
                                                        maxSelectedLabels={0} 
                                                        showSelectAll={false} 
                                                        className="multi-select-dropdown" 
                                                        selectionLimit={10} 
                                                        resetFilterOnHide
                                                    />
                                                </div>
                                                <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className='export-popup' popupAlignment="right" />
                                                <PR.Button icon="pi pi-ellipsis-v" className="export-button" onClick={(event) =>{ setType('tarm'); menuRight.current.toggle(event)}} aria-controls="popup_menu_right" aria-haspopup />
                                            </div>
                                        <Link to={`/my-analytics-details?type=tarm`} className={`${!raisedAlerts ? 'inactive-link': 'active-link'} card mt-4`}>
                                            <PR.Chart type="bar" ref={chartRefAlerts} data={alertsChart} options={chartOptions}/>
                                        </Link>
                                </div>
                            </div>

                            {/* Response Rate Chart */}
                            <div className="col-6">
                                <div className="info-box">
                                    <div className='pr-0'>
                                        <h4>Response Rate</h4>
                                        <p>The % of alerts responded to by each organisation in the selected date range</p>
                                    </div>
                                    <div className="card flex">
                                            <div className="flex flex-row gap-3 w-full">
                                              <PR.MultiSelect 
                                                        filter 
                                                        value={selectedMemberResRates} 
                                                        onChange={(e) => setSelectedMemberResRates(e.value)} 
                                                        options={membersData} 
                                                        optionLabel="name" 
                                                        placeholder="Select Members" 
                                                        maxSelectedLabels={0} 
                                                        showSelectAll={false} 
                                                        className="multi-select-dropdown" 
                                                        selectionLimit={10} 
                                                        resetFilterOnHide
                                                />
                                            </div>
                                            <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className='export-popup' popupAlignment="right" />
                                            <PR.Button icon="pi pi-ellipsis-v" className="export-button" onClick={(event) =>{ setType('rrpm'); menuRight.current.toggle(event)}} aria-controls="popup_menu_right" aria-haspopup />
                                    </div>
                                    <Link to={`/my-analytics-details?type=rrpm`} className={`${!responseRates ? 'inactive-link': 'active-link'} card mt-4`}>
                                        <PR.Chart type="bar"  ref={chartRefResponse} data={responseChart} options={chartOptionsWithPerc} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
            <PR.Dialog visible={visible} footer={footerContent} className="export-dialog">
            <div className="export-header-section">
                <h2>Export Graph as</h2>
                <p>Select file format</p>
            </div>
            <div className="export-body-section">
                <PR.Button label="PNG" onClick={() => setExportType('png')} outlined className="export-btns" />
                <PR.Button label="CSV" onClick={() => setExportType('csv')} outlined className="export-btns" />
                <PR.Button label="PDF" onClick={() => setExportType('pdf')} outlined className="export-btns" />
            </div>
            </PR.Dialog>
        </>
    );
};

export default AnalystAnalytics;
