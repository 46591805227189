import React, { useState, useMemo, useCallback, useEffect } from "react";
import WorldMap from "react-svg-worldmap";
import Layout from "../../../layouts/Layout";
import MenuBar from "../../../layouts/MenuBar";
import * as PR from "../../../prime-react/index";
import "./Alerts.scss";
import { getAlertsByLocation, getLocations, getSports } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { countriesData } from "../../../assets/data/countriesCodes";

const RegulatorAlerts = () => {
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth?.sessionData);
    const headers = useMemo(() => {
        return { 
            sessionid: sessionData.sessionId,
            'Content-Type': 'multipart/form-data'
        };
    }, [sessionData.sessionId]);

    const [dates, setDates] = useState(null);
    const [selectCountry, setSelectCountry] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [location, setLocation] = useState([]);
    const [sports, setSports] = useState([]);
    const [sportValue, setSportValue] = useState('');
    const [selectedSport, setSelectedSport] = useState('');
    const [filteredSports, setFilteredSports] = useState([]);
    const [modalCountry, setModalCountry] = useState(null);
    const [alertsData, setAlertsData] = useState([]);

    const getISO2CodeByCountryName = (locationName) => countriesData?.find(country => country?.country === locationName)?.iso2 || null;

    const transformedData = alertsData?.map(item => {
        const countryCode = getISO2CodeByCountryName(item?.locationName);
        if (!countryCode)return null
        return ({
            country: getISO2CodeByCountryName(item?.locationName),
            value: item?.alertsBySports.reduce((total, sport) => total + sport?.alertsCount, 0)
        })
    }).filter(item => item !== null);
    const openModal = () => {
        setModalIsOpen(true);
      };
    
      const closeModal = () => {
        setModalIsOpen(false);
        setSelectCountry(null);
      };
    
      const handleMapClick = (countryData) => {
        const clickedCountry = alertsData?.find(country =>  getISO2CodeByCountryName(country?.locationName) === countryData?.countryCode);
        if (clickedCountry) {
            const totalAlerts = clickedCountry?.alertsBySports?.reduce((total, sport) => total + sport.alertsCount, 0) || 0;
            const country = {
                ...clickedCountry,
                totalAlerts: totalAlerts
            };
            setModalCountry(country);
            openModal();
        }
      };

    const footerContent = (
        <div className="dialog-footer-sec">
            <PR.Button size="small" label="Close" onClick={closeModal} outlined className="logout-btns" />
        </div>
    );

    const filterSportsOnTyping = (event) => {
        setTimeout(() => {
            let _filteredSports;

            if (!event.query.trim().length) {
                _filteredSports = [...sports];
            }
            else {
                _filteredSports = sports.filter((sport) => {
                    return sport?.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredSports(_filteredSports);
        }, 250);
    }
   
    // Get list of all alertsCount
        const getAlertsHandler = useCallback((obj) => {
            getAlertsByLocation(obj, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {   
                    const responseData = response.data;
                        const data = responseData;  
                        const result = (data?.length > 0) ? data : [];
                        setAlertsData(result);
                } else {
                        setAlertsData([]);
                }
            })
        }, [dispatch, headers]);

    // Get list of all the sports
    const getSportsHandler = useCallback(() => {
        getSports(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const sports = responseData;
                const result = (sports?.length > 0) ? sports : [];
                setSports(result);
            } else {
                setSports([]);
            }
        })
    }, [dispatch, headers]);

    // Get list of all the locations
    const getLocationHandler = useCallback(() => {
        getLocations(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                    const locations = responseData;  
                    const result = (locations?.length > 0) ? locations?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                    setLocation(result);
            } else {
                    setLocation([]);
                }
        })
    }, [dispatch, headers]);

    useEffect(() => {
        getSportsHandler();
        getLocationHandler();
    },[getSportsHandler, getLocationHandler]);
     
    

    useEffect(()=>{
        const obj={};
        if (selectCountry) {
            obj.locationID  = selectCountry.id;
        } 
        if (selectedSport) {
            obj.sportID   = selectedSport.id;
        } 
        if (dates && dates?.length > 0) {
                const startDate= moment(dates[0]).format('YYYY-MM-DD');
                const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
                obj.startDate = startDate;
                obj.endDate = endDate;
        }
        getAlertsHandler(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectCountry, selectedSport, dates]);

    return (
        <>
            <Layout>
                <MenuBar/>
                <section className="regulator-alerts">
                    <div className="m-0">
                        <h1>Alerts</h1>
                    </div>
                    <div className="grid grid-nogutter relative">
                        <div className="flex align-items-center search-bar gap-3">
                            <div className="p-input-icon-left w-12">
                                <i className="pi pi-search" />
                                <PR.AutoComplete
                                    placeholder="Search by sport name.."
                                    inputClassName="search-box w-12"
                                    className="w-12"
                                    dropdownAutoFocus={true}
                                    field="name" 
                                    value={sportValue} 
                                    onChange={(e) => { setSportValue(e.value); setSelectedSport('');}}
                                    onSelect={(e) => {
                                        setSelectedSport(e.value);
                                    }}
                                    onUnselect={() => setSelectedSport('')}
                                    suggestions={filteredSports} 
                                    completeMethod={filterSportsOnTyping} 
                                    forceSelection={true}
                                  />
                            </div>
                            <div className="card">
                            <div className='relative custom-clear-calendar'>
                                <PR.Calendar value={dates} onChange={(e) => setDates(e.value)} iconPos="left" readOnlyInput showIcon className='date-calendar' placeholder='Select Date' selectionMode="range" />
                                {dates && (
                                    <div className='custom-clear'>
                                        <i className='pi pi-times' onClick={() => setDates(null)}></i>
                                    </div>
                                )}
                            </div>
                            </div>
                            <div className="card">
                                <PR.Dropdown showClear value={selectCountry} onChange={(e) => setSelectCountry(e.value)} options={location} optionLabel="name" placeholder="Select country" filter resetFilterOnHide className="country-dropdown" />
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        {alertsData.length <= 0 && (
                               <div className="flex justify-content-center w-full mt-3"><p className="text-orange-500">No records found</p></div>
                        )}
                        <div className="col-12 worldmap-section">
                            <WorldMap   
                                color="#6A9106"
                                value-suffix="people"
                                size="xxl"
                                data={transformedData}
                                borderColor="#6A9106"
                                tooltipBgColor="#133201"
                                tooltipTextColor="#fff"
                                valuePrefix=":"
                                valueSuffix="alerts"
                                onClickFunction={(country) => handleMapClick(country)}
                            />
                        </div>
                    </div>
                </section>

                {/* Country Details Modal */}
                <PR.Dialog visible={modalIsOpen} closable={false} header={<span>{modalCountry?.locationName} ({modalCountry?.totalAlerts})</span>} footer={footerContent} onHide={closeModal} className="counts-dialog response-dialog tags-dialog">
                    <div className="dialog-header">
                        <div className='gap-2'>
                            <div className="list min-h-list">
                                {modalCountry?.alertsBySports?.map((item) => (
                                    <ul key={item?.sportID} className="flex list-none align-items-center justify-content-between">
                                        <li>{item?.sportName}</li>
                                        <li>{item?.alertsCount}</li>
                                    </ul>
                                    ))
                                } 
                            </div>
                        </div>
                    </div>
                </PR.Dialog>

            </Layout>
        </>
    );
};

export default RegulatorAlerts;