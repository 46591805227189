import React, { useEffect, useRef, useState } from "react";
import * as PR from "../../prime-react/index";
import { logo } from "../../assets/images";
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from "../../store/auth";
import { Link, useNavigate } from 'react-router-dom';
import Footer from "../../layouts/Footer";
import { loginOtp, resendOtp } from "../../services/api";
import { capitalizeFirstLetter, resendOtpTime } from "../../utils/reuse";

const OTP = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginData = useSelector(state => state.auth.loginData);
    const maskedValue = loginData.passKey?.slice(-4);

    const [errMsg, setErrMsg] = useState('');

    const initialTime = resendOtpTime;
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const [showResend, setShowResend] = useState(false);

    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRefs = useRef([]);

    useEffect(() => {
        // Set an interval to count down every second
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer); // Clear the interval when time is up
                    setShowResend(true);
                    setOtp(new Array(6).fill(''));
                    setErrMsg('');
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, [timeLeft]);

    // Format the time left in MM:SS format
    const formatTime = (time) => {
        const minutes = String(Math.floor(time / 60)).padStart(2, '0');
        const seconds = String(time % 60).padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    const handleChange = (element, index) => {
        const value = element.value;
        if (/^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            // Move focus to next input
            if (index < 5) {
                inputRefs.current[index + 1].focus();
            }
        } else if (value === '') {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            if (otp[index] === '') {
                if (index > 0) {
                    inputRefs.current[index - 1].focus();
                }
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text').trim();
        if (/^\d{6}$/.test(pasteData)) {
            const pasteOtp = pasteData.split('');
            setOtp(pasteOtp);
            // Update all input fields with pasted data
            pasteOtp.forEach((digit, idx) => {
                if (inputRefs.current[idx]) {
                    inputRefs.current[idx].value = digit;
                }
            });
            // Optionally, move focus to the last input or submit the OTP
            inputRefs.current[5].focus();
        }
    };
    

    const consolidatedOtp = otp.join('');

    // Submit OTP
    const handleSubmit = () => {
        const request = { ...loginData, otp: consolidatedOtp };
        const getOTPResponse = (response) => {
            if (response.result === 'SUCCESS') {
                setErrMsg('');
                setOtp(new Array(6).fill(''));
                dispatch(authActions.onOTPverify(response.data));
                if (response.data.role !== "governingbody") {
                    dispatch(authActions.onLogin(response.data));
                } else {
                    setErrMsg('Coming soon for Governing body');
                }
                if (response.data.role === "member") {
                    setTimeout(() => { navigate('/home', { state: 'fromLogin' }) }, 100);
                }
            } else {
                const error = response.data;
                setErrMsg(error === 'otp invalid or expired' ? 'OTP is Invalid / Expired' : capitalizeFirstLetter(error));
            }
        }
        loginOtp(request, getOTPResponse)
    }

    // Resend OTP
    const handleResendOtp = () => {
        setOtp(new Array(6).fill(''));
        const request = { ...loginData };
        const getresendOTPResponse = (response) => {
            if (response.result === 'SUCCESS') {
                setErrMsg('');
                setTimeLeft(initialTime);
                setShowResend(false);
            } else {
                setErrMsg('The session has expired, please try logging in again');
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            }
        }
        resendOtp(request, getresendOTPResponse)
    }

    return (
        <>
            <section className="login-section">
                <div className="grid grid-nogutter">
                    <div className="col-12">
                        <div className="login-wrapper flex align-items-center justify-content-center">
                            <div className="login-info">
                                <PR.Image src={logo} alt="IBIA Logo" className="logo-img" />
                                <h1>Enter OTP</h1>
                                <p>Please enter the One Time Passcode sent to your registered email address.</p>
                                <p className="mt-3">OTP for user reference key ends with ******<b>{maskedValue}</b></p>
                                {/* Form section starts here */}
                                <form autoComplete="off">
                                    <div className='grid'>
                                        <div className="col-12 md:col-12 text-center otp-input-block">
                                            <div className="flex gap-2 justify-content-center align-items-center">
                                            {otp.map((data, index) => (
                                                <PR.InputText
                                                    key={index}
                                                    type="text"
                                                    inputMode="numeric"
                                                    maxLength="1"
                                                    autoComplete="off"
                                                    className="otp-input"
                                                    value={otp[index]}
                                                    onChange={(e) => handleChange(e.target, index)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                    onPaste={index === 0 ? handlePaste : null} // Attach onPaste to the first input
                                                    ref={(el) => inputRefs.current[index] = el}
                                                    aria-label={`OTP digit ${index + 1}`}
                                                />
                                            ))}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-12 text-center otp-input-block">
                                            {!showResend ? (
                                                <p>Resend link will be available in: <b>{formatTime(timeLeft)}</b></p>
                                            )
                                                : <label htmlFor="email" className="mt-3 block resend-label" onClick={handleResendOtp}>Resend OTP</label>
                                            }
                                        </div>
                                        <div className="col-12 mt-2">
                                            <PR.Button label="Sign in" type='button' className="signin-button w-12" onClick={handleSubmit} />
                                        </div>
                                        {errMsg && errMsg !== '' &&
                                            <div className="col-12 md:col-12">
                                                <div className='error-message text-center'>{errMsg}</div>
                                            </div>
                                        }
                                        <div className="col-12">
                                            <p className="text-center link-p"><Link to="/forgot-password">Can't access your account?</Link></p>
                                        </div>
                                    </div>
                                </form>
                                {/* Form section ends here */}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </section>
        </>
    );
};

export default OTP;
