import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as PR from "../../../prime-react/index";
import "./Analytics.scss";
import Layout from '../../../layouts/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getAlertsCount, getGroupsNames } from '../../../services/api';
import moment from 'moment';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { jsPDF } from "jspdf";
import { optionsforBarGraphsDetails, optionsforBarGraphsWithPercentageDetails } from '../../../utils/reuse';

const AnalystAnalyticsDetails = () => {

    const [membersData, setMembersData] = useState([]);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const type = queryParams.get("type");
    const dispatch = useDispatch();
    const isExport = useSelector(state => state.auth?.exportPermission);

    let title, description, downloadedFilename;

       // Select stored data based on type
       const storedAnalyticsData = useSelector(state => {
        switch (type) {
            case 'tarm':
                return state.auth?.analystAlertRaisedAnalytics;
            case 'rrpm':
                return state.auth?.analystResponseRateAnalytics;
            default:
                return [];
        }
    });

        //  title and description based on type
        switch (type) {
            case 'tarm':
              title = 'Total Alerts Raised';
              description = 'The number of alerts raised by each organisation in the selected date range';
              downloadedFilename = 'total-alerts-raised-by-members'
              break;
            case 'rrpm':
              title = 'Response Rate';
              description = 'The % of alerts responded to by each organisation in the selected date range';
              downloadedFilename = 'response-rates-by-members'
              break;
            default:
              title = 'Analytics Data';
              description = 'General analytics overview.';
              downloadedFilename = "analytics-data"
          }

    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);

    const [dates, setDates] = useState(storedAnalyticsData?.selectedDate);
    const [chartOptions, setChartOptions] = useState(optionsforBarGraphsDetails);
    const [chartOptionsWithPerc, setChartOptionsWithPerc] = useState(optionsforBarGraphsWithPercentageDetails);

    const [selectedDataValue, setSelectedDataValue] = useState(storedAnalyticsData?.selectedData);
    const [chartDataNew, setChartDataNew] = useState({});

    const [visible, setVisible] = useState(false);
    const [exportType, setExportType] = useState(null);


    const emptyData = {
        labels: null,
        datasets: [
            {
                label: 'Members',
                data: null,
                backgroundColor: '#3067C2',
                borderRadius: 5,
                barThickness: 25,
            },
        ]
    };

    const processData = (data, setDataFunction) => {
        if (data) {
            const filteredData = data?.filter(item => {
                const value = item?.alertCount || item?.responseRate || 0;
                return value > 0;
            });
            const labels = filteredData?.map(item => `${item?.groupName}`);
            const dataValues = filteredData?.map(item => item?.alertCount || item?.responseRate || 0);

            const chartData = {
                labels: labels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: '#3067C2',
                        borderRadius: 5,
                        barThickness: 25,
                    },
                ]
            };

            setDataFunction(chartData);
            setChartOptions(optionsforBarGraphsDetails);
            setChartOptionsWithPerc(optionsforBarGraphsWithPercentageDetails)

        } else {
            setDataFunction(emptyData);
        }
    };

      // Api to fetch analytics data Of sports
      const fetchAnalyticData = useCallback((selectedDataValue) => {
        const obj = {
            type: type,
        }; 
        if (dates && dates?.length > 0) {
            const startDate= moment(dates[0]).format('YYYY-MM-DD');
            const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        if(selectedDataValue?.length > 0)
            {
                obj.members  = selectedDataValue?.map(group  => group.id).join(',');
            }
        getAlertsCount(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                if( type === 'tarm') {
                    processData(responseData?.alertsRaisedPerMember, setChartDataNew);
                    setDataForExport(responseData?.alertsRaisedPerMember);
                } else if( type ==='rrpm'){
                    processData(responseData?.responseRatePerMember, setChartDataNew);
                    setDataForExport(responseData?.responseRatePerMember);
                } 
            }
        })
    // eslint-disable-next-line
    }, [dispatch, headers, dates]);

    useEffect(()=>{
        fetchAnalyticData(selectedDataValue);
        // eslint-disable-next-line
    },[selectedDataValue, dates])


    //API to get the groupsNames
    const getGroupsHandler = useCallback(() => {
    getGroupsNames(headers, dispatch, response => {
        if (response.result === 'SUCCESS') {   
            const responseData = response?.data ? response?.data?.sort((a, b) => a.name.localeCompare(b.name)) : [] ;
            setMembersData(responseData);
        }
    })
}, [dispatch, headers]);    


    useEffect(() => {
        getGroupsHandler();
    },[ getGroupsHandler])

    const chartRef = useRef(null);
    const [dataForExport, setDataForExport] = useState(null);

    const handleDownloadFile = (exportType) => {
        const currDate = `_${moment(dates[0]).format('DD_MMM_YYYY')}${moment(dates[1]).isValid() ? `_to_${moment(dates[1]).format('DD_MMM_YYYY')}` : ''}`;
        downloadedFilename =  downloadedFilename + currDate;
        
        const csvConfig = mkConfig({ useKeysAsHeaders: true , filename: downloadedFilename});

        if(exportType === 'png'){
            if (chartRef.current) {
                const base64Image = chartRef.current.getBase64Image();
                const link = document.createElement('a');
                link.href = base64Image;
                link.download = `${downloadedFilename}`;
                link.click();
            }
        }
        else if(exportType === 'csv'){
            const csv = generateCsv(csvConfig)(dataForExport);
            download(csvConfig)(csv)
        }

        else if (exportType === 'pdf') {
            const base64Image = chartRef.current.getBase64Image();
            const doc = new jsPDF();
            doc.addImage(base64Image, 'PNG', 10, 10);
            doc.save(`${downloadedFilename}.pdf`);
        }
        setExportType(null);
        setVisible(false);
    }


    const footerContent = (
        <div>
            <PR.Button label="Cancel" onClick={() => setVisible(false)} outlined className="logout-btns" />
            <PR.Button label="Export" onClick={() => handleDownloadFile(exportType)} disabled={!exportType} outlined autoFocus className={`logout-btns click-btn`} />
        </div>
    );

    return (
        <>
            <Layout>
                <div className='menu-section'>
                    <Link to="/my-analytics" className='back-link'><i className="pi pi-arrow-left"></i> Back to Analytics</Link>
                </div>
                <section className="my-analytics-tab">
                    <div className="m-0 flex align-items-center justify-content-between">
                        <div className='heading-card'>
                            <h1>{title}</h1>
                            <p>{description}</p>
                        </div>
                        <div className="header-right flex gap-2">
                            <div className='date'>
                            <PR.Calendar 
                                value={dates} 
                                onChange={(e) => setDates(e.value)} 
                                selectionMode="range" 
                                iconPos="left" 
                                readOnlyInput 
                                showIcon 
                                className='date-calendar' 
                                placeholder='Date'
                                dateFormat={"dd M yy"}
                                maxDate={new Date()}
                            />
                            </div>
                            <div className="flex flex-row gap-3 w-full">
                            <PR.MultiSelect 
                                filter
                                value={selectedDataValue}
                                onChange={(e) => setSelectedDataValue(e.value)} 
                                options={membersData} 
                                optionLabel="name" 
                                placeholder="Select Members"
                                maxSelectedLabels={0}
                                showSelectAll={false}
                                className="multi-select-dropdown"
                                selectionLimit={10} 
                                resetFilterOnHide
                            />
                            </div>
                            <div className="export-block">
                                <PR.Button label="Export" disabled={!isExport || !dataForExport} onClick={() => { setVisible(true)}} icon="pi pi-upload" className="export-button" />
                            </div>
                        </div>
                    </div>
                    <div className="analytics-wrapper">
                        <div className="grid align-items-start mt-3">
                            <div className="col-12">
                                <div className="info-box max-chart">
                                    <div className="chartbar-details-view" style={{ width: '100%'}}>
                                        <PR.Chart type="bar" ref={chartRef} data={chartDataNew} options={type === 'tarm' ? chartOptions : chartOptionsWithPerc} height={400}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
            <PR.Dialog visible={visible} footer={footerContent} className="export-dialog">
            <div className="export-header-section">
                <h2>Export Graph as</h2>
                <p>Select file format</p>
            </div>
            <div className="export-body-section">
                <PR.Button label="PNG" onClick={() => setExportType('png')} outlined className="export-btns" />
                <PR.Button label="CSV" onClick={() => setExportType('csv')} outlined className="export-btns" />
                <PR.Button label="PDF" onClick={() => setExportType('pdf')} outlined className="export-btns" />
            </div>
            </PR.Dialog>
        </>
    );
};

export default AnalystAnalyticsDetails;